import {
  AutocompleteInput,
  DateField,
  DateInput,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  SelectColumnsButton,
  FilterButton,
  ExportButton,
  CreateButton,
  DatagridConfigurable,
} from "react-admin";
import * as React from "react";
import { CurrencyNumberField } from "../CurrencyNumberField";
import MargeCurrencyField from "../MargeCurrencyField";

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="customer"
    reference="relations"
    filter={{ customer: true }}
    alwaysOn
  >
    <AutocompleteInput />
  </ReferenceInput>,
  <DateInput label="Created Since" source="createdAt_gte" alwaysOn />,
];

const QuoteListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const QuoteList = (props) => (
  <List
    {...props}
    filters={orderFilters}
    filter={{ state: "OPEN" }}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={25}
    actions={<QuoteListActions />}
  >
    <DatagridConfigurable rowClick="show">
      <DateField source="createdAt" showTime />
      <TextField source="orderNumber" />
      <ReferenceField
        label="Customer"
        reference="relations"
        source="customer.id"
        link={false}
      />
      <NumberField source="totalQty" />
      <CurrencyNumberField
        source="grandTotal"
        currency="currencyCode"
        sx={{ fontWeight: "bold" }}
      />
      {/*<MargeCurrencyField
        source="margin"
        total="grandTotalWithoutCoreDeposit"
        currency="currencyCode"
        dealerOrder="dealerOrder"
      />*/}
      <TextField source="remark" />
    </DatagridConfigurable>
  </List>
);
