import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
  ReferenceField,
  SearchInput,
  ReferenceInput,
  DateInput,
  TextInput,
  SelectInput,
  DatagridConfigurable,
  TopToolbar,
  SelectColumnsButton,
  FilterButton,
  ExportButton,
  CreateButton,
} from "react-admin";

const purchaseFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="supplier"
    reference="relations"
    filter={{ supplier: true }}
    sort={{ field: "company", order: "ASC" }}
    alwaysOn
  />,
  <DateInput label="Created Since" source="orderDate_gte" />,
  <SelectInput alwaysOn
    source="state"
    choices={[
      {
        id: "COMPLETE",
        name: "COMPLETE",
      },
      {
        id: "PROCESSING",
        name: "PROCESSING",
      },
      {
        id: "CANCELED",
        name: "CANCELED",
      },
    ]}
  />,
  <TextInput source="trackingNumber" />,
];

const PurchaseListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const PurchaseList = () => {
  return (
    <List
      perPage={25}
      filters={purchaseFilters}
      actions={<PurchaseListActions />}
      sort={{ field: "orderDate", order: "DESC" }}
      filterDefaultValues = {{state : "PROCESSING"}}
    >
      <DatagridConfigurable rowClick="show">
        <DateField source="orderDate" />
        <DateField source="invoiceDate" />
        <TextField source="orderNumber" />
        <ReferenceField
          label="Supplier"
          reference="relations"
          source="supplier.id"
          link={false}
        >
          <FunctionField
            label="Company"
            sortBy="company"
            render={(record) => `${record.company}`}
          />
        </ReferenceField>
        <DateField label="Delivery Date" source="deliveryDate" />
        <TextField source="state" />
        <BooleanField source="invoiced" />
        <BooleanField source="received" />
        <TextField source="remark" />
      </DatagridConfigurable>
    </List>
  );
};
